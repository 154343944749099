import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ParteTrabajoAlbaranFilter } from './ParteTrabajoAlbaranFilter';
import { ParteTrabajoFechaFilter } from './ParteTrabajoFechaFilter';
import { ParteTrabajoOperarioFilter } from './ParteTrabajoOperarioFilter';
import { ParteTrabajoRevisadoFilter } from './ParteTrabajoRevisadoFilter';

export default function ParteTrabajoListFilters({ jornadas, onFilter }) {
    const operariosList = useMemo(
        () =>
            Object.entries(
                jornadas
                    .filter((j) => j.operario_id)
                    .reduce((list, jornada) => {
                        list[jornada.operario_id] = jornada.operario;
                        return list;
                    }, {}),
            ).map(([id, nombre]) => ({ id, nombre })),
        [jornadas],
    );

    return (
        <>
            <ParteTrabajoFechaFilter onFilterChange={(range) => onFilter('fecha', range)} />
            <ParteTrabajoOperarioFilter
                operarios={operariosList}
                onFilterChange={(operarioId) => onFilter('operario', operarioId)}
            />
            <ParteTrabajoRevisadoFilter onFilterChange={(revisado) => onFilter('revisado', revisado)} />
            <ParteTrabajoAlbaranFilter onFilterChange={(albaran) => onFilter('albaran', albaran)} />
        </>
    );
}

ParteTrabajoListFilters.propTypes = {
    jornadas: PropTypes.any,
    onFilter: PropTypes.any,
};
