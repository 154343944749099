import DataProvider from './DataProvider';

export const empty = {
    numero: '',
    referencia_interna: '',
    nombre: '',
    notas: '',
    estado: '',
    fecha_creacion: null,
    fecha_envio: null,
    fecha_final: null,
    cliente: null,
    cliente_id: null,
    direccion: null,
    direccion_id: null,
    proyecto: null,
    proyecto_id: null,
    adjuntos: [],
    lineas: [],
    importe: null,
    importe_coste: null,
    importe_venta: null,
    descuento_venta: null,
    total_venta: null,
    pie_pagina: null,
};

export const estadoPresupuestoLabels = {
    PENDIENTE: 'Pendiente',
    ENVIADO: 'Enviado',
    ACEPTADO: 'Aceptado',
    RECHAZADO: 'Rechazado',
    CANCELADO: 'Cancelado',
};

export function isEstadoFinal(estado) {
    return ['ACEPTADO', 'RECHAZADO', 'CANCELADO'].includes(estado);
}

export const estadoPresupuestoOptions = Object.entries(estadoPresupuestoLabels).map(([value, label]) => ({
    value,
    label,
}));

export const tipoPresupuestoLineaLabels = {
    CAPITULO: 'Capítulo',
    PARTIDA: 'Partida',
    MANO_OBRA: 'Mano de obra',
    MATERIAL: 'Material',
};

export const tipoUnidadesShortLabels = {
    UNIDADES: 'ud',
    METROS_LINEALES: 'm',
    METROS_CUADRADOS: 'm²',
    METROS_CUBICOS: 'm³',
    KILOMETROS: 'km',
    KILOGRAMOS: 'kg',
    TONELADAS: 'tm',
    LITROS: 'lt',
    HORAS: 'h',
};

export const tipoUnidadesLabels = {
    UNIDADES: 'Unidades',
    METROS_LINEALES: 'Metros lineales',
    METROS_CUADRADOS: 'Metros cuadrados',
    METROS_CUBICOS: 'Metros cúbicos',
    KILOMETROS: 'Kilómetros',
    KILOGRAMOS: 'Kilogramos',
    TONELADAS: 'Toneladas',
    LITROS: 'Litros',
    HORAS: 'Horas',
};

export const tipoUnidadesOptions = Object.entries(tipoUnidadesLabels).map(([value, label]) => ({
    value,
    label,
}));

class PresupuestosProvider extends DataProvider {
    constructor() {
        super('presupuestos', empty);
    }

    getAsOptions = async () => this.getAll('as_options');

    duplicar = async (presupuestoId) => this.actionOnId(presupuestoId, 'duplicar');

    addLinea = async (presupuestoId, values) => this.actionOnId(presupuestoId, 'lineas', values);
    updateLinea = async (presupuestoId, lineaId, values) => this.actionOnId(presupuestoId, `lineas/${lineaId}`, values);
    deleteLinea = async (presupuestoId, lineaId) =>
        this.actionOnId(presupuestoId, `lineas/${lineaId}`, null, { method: 'delete' });

    moverLinea = async (presupuestoId, lineaId, values) =>
        this.actionOnId(presupuestoId, `lineas/${lineaId}/mover`, values);

    reordenarLineas = async (presupuestoId, values) => this.actionOnId(presupuestoId, 'lineas/reordenar', values);

    editarPie = async (presupuestoId, values) => this.actionOnId(presupuestoId, 'pie-pagina', values);
    getPdfURL = (id, params) => this.generateUrl(`${id}/pdf?${new URLSearchParams(params).toString()}`);
    generarPdf = (id, params) => this.getAll(`${id}/pdf-async?${new URLSearchParams(params).toString()}`);

    searchLinea = async (params) => this.getAll(`lineas/search?${new URLSearchParams(params).toString()}`);

    aplicarMargen = async (presupuestoId, lineaId, margen) =>
        this.actionOnId(presupuestoId, 'margen', { margen, linea_id: lineaId });

    generarFichaPdf = (id) => this.getAll(`${id}/ficha-pdf-async`);

    getFichaPdfURL = (id) => this.generateUrl(`${id}/ficha-pdf`);

    borrarLineas = async (presupuestoId, lineaIds) =>
        this.actionOnId(presupuestoId, 'lineas', { linea_ids: lineaIds }, { method: 'delete' });

    pegarLineas = async (presupuestoId, parentId, lineaIds) =>
        this.actionOnId(presupuestoId, 'lineas/pegar', { parent_id: parentId, linea_ids: lineaIds });

    getHistoricoObservacionesAvance = async (avanceId) => this.getAll(`avances/${avanceId}/observaciones`);
}

export const dataProvider = new PresupuestosProvider();
